import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './DownloadScreen.css';

const DownloadScreen = () => {
  const [countdown, setCountdown] = useState(5);
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsDownloading(true);
      const downloadLink = document.createElement("a");
      downloadLink.href =
        "https://bgmulnulnvbjwvwkhuuq.supabase.co/storage/v1/object/public/apk-releases/cricket-combat.apk?t=2024-11-10T13%3A26%3A02.108Z";
      downloadLink.setAttribute("download", "CricketCombat.apk");
      downloadLink.click();
    }
  }, [countdown]);

  const handleCloseClick = () => {
    navigate("/");
  };

  return (
    <div className="download-screen">
      <div className="navbar-download">
        <img src="/cricket_combat_logo.jpg" height={60} width={60}  />
        <div className="navbar-title">  CRICKET COMBAT </div>
  
        <button
          onClick={handleCloseClick}
          className="close-button"
        >
          &times;
        </button>
      </div>

      {/* Countdown or Downloading State */}
      <div className="downloading-state">
        {isDownloading ? (
          <h3>Downloading...</h3>
        ) : (
          <h3>Download starts in {countdown} seconds</h3>
        )}
      </div>

      {/* Main Instructions */}
        <h3 className="how-to">
          How to Install <span className="highlight">Cricket Combat</span> App?
        </h3>
 <div className="instructions-container">
          <div className="download-step">
          <div className="instruction-text">
        <span className="step-number">{1}.</span>
        <p className="instruction">Tap 'Ok' to download the app</p>
      </div>
            <div className="mock-action">
              <div className="file-warning">
                <p>
                  This type of file can harm your device.<br />
                  Do you want to keep <strong>cricket-combat.apk</strong> anyway?
                </p>
                <div className="warning-buttons">
                  <button className="button cancel">Cancel</button>
                  <button className=" button positive-button ok pop-animation">Ok</button>
                </div>
              </div>
            </div>
          </div>
     
          <div className="download-step">
          <div className="instruction-text">
        <span className="step-number">{2}.</span>
        <p className="instruction">Open the downloaded file to start installation</p>
      </div>
            <div className="mock-action">
              <div className="file-open">
              <img src= "/downloaded_icon.png" className="mock-action-logo"/>
                <span className="file-name">cricket-combat.apk</span>
                <span className="pop-animation">Open</span>
              </div>
            </div>
          </div>
               <div className="download-step">        <div className="instruction-text">
        <span className="step-number">{3}.</span>

        <p className="instruction">Approve the installation from unknown sources</p>
      </div>
            <div className="mock-action">
              <div className="unknown-sources"><img src="/left_arrow.png" className="mock-action-logo"/>
                <p> Install Unknown Apps </p></div>
              <div className="unknown-radio">   <p>Allow from this source</p>
              <div className="radio">
                <div className="toggle"></div>
              </div></div>
           
            </div></div>
               <div className="download-step">     <div className="instruction-text">
        <span className="step-number">{4}.</span>
        <p className="instruction">Tap install to complete the installation</p>
      </div>
            <div className="mock-action">
              <div className="app-info">
               <img src ="/cricket_combat_logo.jpg"className ='mock-action-app-logo'/>
               <p className="app-name">Cricket Combat</p>
               
              </div>
              <p className="mock-action-question">Do you want to install this app?</p>
              <div className="buttons">
                  <button className=" butto cancel">Cancel</button>
                  <button className="button positive-button install zoom-animation">Install</button>
                </div>
            </div></div>
               <div className="download-step">   <div className="instruction-text">
        <span className="step-number">{5}.</span>
        <p className="instruction"> Open the App to start playing</p>
      </div>
            <div className="mock-action">
              <div className="app-info">
              <img src ="/cricket_combat_logo.jpg"className ='mock-action-app-logo'/> 
              <p className="app-name">Cricket Combat</p>
             
              </div>
              <p className="mock-action-question">Install completed</p>

              <div className="buttons">
                  <button className=" button cancel">Done</button>
                  <button className="button positive-button install zoom-animation">Open</button>
                </div>
            </div></div>


            </div>
            <div className="safe-secure">
              <img src="/shield.png" className="mock-action-app-logo"   />
              <p> 100% Safe and Secure</p>
            </div>
          </div>
  );
};

export default DownloadScreen;